import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectCliniciansInitQuery, Location } from './queries';
import { ListClinicianUsersComponent } from '../../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  location_id: string;
};

const ListClinicianUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });
  const { data: locationInitData } = useQuery(selectCliniciansInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData) {
      setLocation(locationInitData.locations_by_pk);
    }
  }, [locationInitData]);

  const handleView = (clinicianId: string) => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/clinicians/${clinicianId}`);
  };

  const handleEdit = (clinicianId: string) => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/clinicians/${clinicianId}/edit`);
  };

  const handleCreate = () => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/clinicians/create`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Clinicians' },
      ]}>
      <ListClinicianUsersComponent locationId={location_id!} handleView={handleView} handleEdit={handleEdit} handleCreate={handleCreate} />
    </DashboardLayout>
  );
};

export default ListClinicianUsers;
