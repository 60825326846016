import React, { useState, useEffect, useReducer } from 'react';
import { CustomBackdrop, CustomDialog } from '@blumtechgroup/blum-react-core-components';
import { LicenseTypeAssignment, locationReducer, LocationReducerAction } from '../Common';
import Steps from '../Common/components/Steps';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  editLocationInitQuery,
  Organisation,
  getAllocatedLicensesQuery,
  GetAllocatedLicensesResponse,
  GetAllocatedLicensesVariables,
  getAllocatedLicensesForOrganisationQuery,
} from './queries';
import { updateLocationMutation } from './mutations';

interface Props {
  organisationId?: string;
  locationId: string;
  handleFinish: () => void;
}

const LocationEditComponent = ({ organisationId, locationId, handleFinish }: Props): React.ReactElement => {
  const [location, dispatchLocation] = useReducer(locationReducer, {
    name: '',
    active: true,
    license_type_assignments: [],
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [updateDenied, setUpdateDenied] = useState<boolean>(false);
  const [deleteDenied, setDeleteDenied] = useState<boolean>(false);

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    license_type_assignments: [],
    location_license_type_assignments: [],
  });

  const { data: editLocationInitQueryData } = useQuery(editLocationInitQuery(locationId), { fetchPolicy: 'no-cache' });
  const [updateLocation] = useMutation(updateLocationMutation);

  const [getAllocatedLicenses] = useLazyQuery<GetAllocatedLicensesResponse, GetAllocatedLicensesVariables>(
    organisationId ? getAllocatedLicensesForOrganisationQuery : getAllocatedLicensesQuery
  );

  useEffect(() => {
    if (editLocationInitQueryData) {
      const { locations_by_pk } = editLocationInitQueryData;
      dispatchLocation({ type: LocationReducerAction.INIT, value: locations_by_pk });
      setOrganisation(locations_by_pk.organisation);
    }
  }, [editLocationInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const { license_type_assignments, id, ...set } = location;

    const { locations_by_pk: originalLocation } = editLocationInitQueryData;

    const insertLicenses = license_type_assignments.map((item) => ({
      license_type_id: item.license_type_id,
      count: item.count,
      location_id: locationId,
      organisation_id: organisationId,
    }));

    const deleteLicenseTypes: string[] = originalLocation.license_type_assignments
      .filter((item: LicenseTypeAssignment) => !location.license_type_assignments.some((l) => l.license_type_id === item.license_type_id))
      .map((item: LicenseTypeAssignment) => item.license_type_id);

    const { data: response } = await getAllocatedLicenses({
      variables: {
        licenseTypeIds: [...insertLicenses.map((item) => item.license_type_id), ...deleteLicenseTypes],
        organisationId,
      },
    });

    const allocatedLicenses = response ? response.location_license_type_assignments : [];

    const deleteNotAllowed = deleteLicenseTypes.some((item) => allocatedLicenses.some((i) => i.license_type_id === item));

    setDeleteDenied(deleteNotAllowed);
    if (deleteNotAllowed) {
      setSaving(false);

      return;
    }

    const updateNotAllowed = insertLicenses.some((item) => {
      const available = organisation.license_type_assignments.find((i) => i.license_type_id === item.license_type_id)?.count ?? 0;
      const allocated = allocatedLicenses
        .filter((i) => i.location_id !== locationId)
        .filter((i) => i.license_type_id === item.license_type_id)
        .map((i) => i.count)
        .reduce((t, i) => t + i, 0);

      const assigned = allocatedLicenses
        .filter((i) => i.location_id === locationId)
        .filter((i) => i.license_type_id === item.license_type_id)
        .map((i) => i.count)
        .reduce((t, i) => t + i, 0);

      return allocated + item.count > available || item.count < assigned;
    });

    setUpdateDenied(updateNotAllowed);
    if (updateNotAllowed) {
      setSaving(false);

      return;
    }

    const updateVariables = {
      pk_columns: {
        id: locationId,
      },
      set: {
        ...set,
        organisation_id: organisationId,
      },
      license_types: insertLicenses,
      delete_license_types: deleteLicenseTypes,
      location_id: locationId,
    };

    await updateLocation({ variables: updateVariables });

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        location={location}
        licenseTypes={organisation.license_type_assignments}
        assignedLicenses={organisation.location_license_type_assignments}
        dispatch={dispatchLocation}
        handleSave={handleSave}
      />
      {saving && <CustomBackdrop label="Saving Changes" />}
      <CustomDialog
        open={updateDenied}
        title={'Update location'}
        subtitle={'Unable to set a quota for one or more license types.'}
        message={
          "Please, make sure that the number of licenses does not bigger than the number of available licenses or less that the number of already assigned licenses (also changes can be made by someone else, while you're editing)."
        }
        actions={[
          {
            label: 'Close',
            onClick: () => setUpdateDenied(false),
          },
        ]}
        handleClose={() => setUpdateDenied(false)}
      />
      <CustomDialog
        open={deleteDenied}
        title={'Update location'}
        subtitle={'Unable to delete a license type(s).'}
        message={"Please, make sure that the license type(s) not assigned to any of the clinicians (also changes can be made by someone else, while you're editing)."}
        actions={[
          {
            label: 'Close',
            onClick: () => setDeleteDenied(false),
          },
        ]}
        handleClose={() => setDeleteDenied(false)}
      />
    </>
  );
};

export default LocationEditComponent;
