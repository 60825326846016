import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { editLocationInitQuery, Location } from './queries';
import { LocationEditComponent } from '../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  location_id: string;
};

const LocationEdit = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: editLocationInitQueryData } = useQuery(editLocationInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (editLocationInitQueryData) {
      const { locations_by_pk } = editLocationInitQueryData;
      setLocation(locations_by_pk);
    }
  }, [editLocationInitQueryData]);

  const handleFinish = () => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Edit' },
      ]}>
      <LocationEditComponent organisationId={organisation_id!} locationId={location_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default LocationEdit;
