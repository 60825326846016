import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Clinician, editClinicianUserInitQuery } from './queries';
import { EditClinicianUsersComponent } from '../../../../../components/Global';

type UrlParams = {
  location_id: string;
  clinician_id: string;
};

const EditClinicianUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { location_id, clinician_id } = useParams<UrlParams>();

  const [clinician, setClinician] = useState<Clinician>({
    first_name: '',
    last_name: '',
    location: {
      name: '',
      organisation: {
        name: '',
      },
    },
  });

  const { data: editClinicianUserInitData } = useQuery(editClinicianUserInitQuery(clinician_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (editClinicianUserInitData) {
      const { users_clinician_by_pk } = editClinicianUserInitData;
      setClinician(users_clinician_by_pk);
    }
  }, [editClinicianUserInitData]);

  const handleFinish = () => {
    navigate(`/org/locations/${location_id}/clinicians/${clinician_id}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Locations', link: `/org/locations` },
        { label: clinician.location.name, link: `/org/locations/${location_id}` },
        { label: 'Clinicians', link: `/org/locations/${location_id}/clinicians` },
        {
          label: StringUtils.stringFromArray([clinician.first_name, clinician.last_name]),
          link: `/org/locations/${location_id}/clinicians/${clinician_id}`,
        },
        { label: 'Edit' },
      ]}>
      <EditClinicianUsersComponent locationId={location_id!} clinicianId={clinician_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default EditClinicianUsers;
