import React, { useState, useReducer, useEffect } from 'react';
import { CustomBackdrop, CustomDialog } from '@blumtechgroup/blum-react-core-components';
import { locationReducer } from '../Common';
import Steps from '../Common/components/Steps';
import { insertLocationMutation, InsertLocationResponse } from './mutations';
import {
  AllocatedLicensesResponse,
  AllocatedLicensesVariables,
  createLocationInitQuery,
  getAllocatedLicensesForOrganisationQuery,
  getAllocatedLicensesQuery,
  Organisation,
} from './queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

interface Props {
  organisationId?: string;
  handleFinish: (locationId: string) => void;
}

const LocationCreateComponent = ({ organisationId, handleFinish }: Props): React.ReactElement => {
  const [location, dispatchLocation] = useReducer(locationReducer, {
    name: '',
    active: true,
    license_type_assignments: [],
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [updateDenied, setUpdateDenied] = useState<boolean>(false);

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    license_type_assignments: [],
    location_license_type_assignments: [],
  });

  const { data: organisationInitData } = useQuery(createLocationInitQuery(organisationId), { fetchPolicy: 'no-cache' });
  const [createLocation] = useMutation<InsertLocationResponse>(insertLocationMutation);

  const [getAllocatedLicenses] = useLazyQuery<AllocatedLicensesResponse, AllocatedLicensesVariables>(
    organisationId ? getAllocatedLicensesForOrganisationQuery : getAllocatedLicensesQuery
  );

  useEffect(() => {
    if (organisationInitData && organisationInitData.organisations.length) {
      setOrganisation(organisationInitData.organisations[0]);
    }
  }, [organisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const { license_type_assignments, ...obj } = location;

    const variables: AllocatedLicensesVariables = {
      licenseTypeIds: license_type_assignments.map((item) => item.license_type_id),
      organisationId,
    };

    const { data: response } = await getAllocatedLicenses({ variables: variables });
    const allocatedLicenses = response ? response.location_license_type_assignments : [];

    const updateNotAllowed = license_type_assignments.some((item) => {
      const available = organisation.license_type_assignments.find((i) => i.license_type_id === item.license_type_id)?.count ?? 0;
      const allocated = allocatedLicenses
        .filter((i) => i.license_type_id === item.license_type_id)
        .map((i) => i.count)
        .reduce((t, i) => t + i, 0);

      return allocated + item.count > available;
    });

    setUpdateDenied(updateNotAllowed);
    if (updateNotAllowed) {
      setSaving(false);

      return;
    }

    const createVariables = {
      object: {
        ...obj,
        organisation_id: organisationId,
        license_type_assignments: {
          data: license_type_assignments.map((item) => ({
            license_type_id: item.license_type_id,
            count: item.count,
            organisation_id: organisationId,
          })),
        },
      },
    };
    const { data } = await createLocation({ variables: createVariables });
    if (data) {
      setSaving(false);
      handleFinish(data.insert_locations_one.id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        location={location}
        licenseTypes={organisation.license_type_assignments}
        assignedLicenses={organisation.location_license_type_assignments}
        dispatch={dispatchLocation}
        handleSave={handleSave}
      />
      {saving && <CustomBackdrop label="Creating Location" />}
      <CustomDialog
        open={updateDenied}
        title={'Update location'}
        subtitle={'Unable to set a quota for one or more license types.'}
        message={
          "Please, make sure that the number of licenses does not bigger than the number of available licenses (also changes can be made by someone else, while you're editing)."
        }
        actions={[
          {
            label: 'Close',
            onClick: () => setUpdateDenied(false),
          },
        ]}
        handleClose={() => setUpdateDenied(false)}
      />
    </>
  );
};

export default LocationCreateComponent;
