import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useNavigate, useParams } from 'react-router-dom';
import { createLocationInitQuery, Organisation } from './queries';
import { useQuery } from '@apollo/client';
import { LocationCreateComponent } from '../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
};

const LocationCreate = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });

  const { data: organisationInitData } = useQuery(createLocationInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationInitData) {
      setOrganisation(organisationInitData.organisations_by_pk);
    }
  }, [organisationInitData]);

  const handleFinish = (locationId: string) => {
    navigate(`/admin/organisations/${organisation_id}/locations/${locationId}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: 'Create' },
      ]}>
      <LocationCreateComponent organisationId={organisation_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default LocationCreate;
