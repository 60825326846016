import React, { useState, useEffect } from 'react';
import { Grid, InputBase, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  CustomSortableTable,
  CustomViewToggle,
  CustomViewToggleViewType,
  GridActionCard,
  GridCard,
  SortableTableFilter,
  SortableTableRow,
} from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useQuery } from '@apollo/client';
import { selectLocationsInitQuery, Organisation } from './queries';
import { Search as SearchIcon, Clear as ClearSearchIcon } from '@mui/icons-material/';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { SearchIconContainer, SearchPaper } from './styled';

interface Props {
  organisationId: string;
  handleView: (locationId: string) => void;
  handleEdit: (locationId: string) => void;
  handleCreate: () => void;
}

const ListLocationsComponent = ({ organisationId, handleView, handleEdit, handleCreate }: Props): React.ReactElement => {
  const [activeFiltered, setActiveFiltered] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');

  const [viewMode, setViewMode] = useLocalStorage<CustomViewToggleViewType>('admin_locations_list_view_mode', CustomViewToggleViewType.TABLE);

  const [pageState, setPageState] = useState<Organisation>({
    name: '',
    locations: [],
  });
  const { data: locationsInitData } = useQuery(selectLocationsInitQuery(organisationId), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationsInitData) {
      setPageState(locationsInitData.organisations_by_pk);
    }
  }, [locationsInitData]);

  const rows = pageState.locations.map((item) => {
    return {
      key: item.id,
      actions: [
        {
          label: 'View',
          onClick: () => handleView(item.id),
        },
        {
          label: 'Edit',
          onClick: () => handleEdit(item.id),
        },
      ],
      columns: [
        {
          key: 'name',
          label: item.name,
        },
        {
          key: 'active',
          label: StringUtils.booleanToYesNo(item.active),
        },
      ],
    };
  });

  const activeFilter: SortableTableFilter = {
    key: 'activeFilter',
    label: 'Active',
    filter: (rows: SortableTableRow[]) =>
      rows.filter((r) => {
        const col = r.columns.find((c) => c.key === 'active');
        if (col) {
          return activeFiltered ? col.label === 'Yes' : col.label === 'No';
        }
        return true;
      }),
    component: (
      <ToggleButtonGroup size="medium" value={[activeFiltered]} color="primary" onChange={() => setActiveFiltered(!activeFiltered)}>
        <ToggleButton key="active" value={true}>
          Active
        </ToggleButton>

        <ToggleButton key="inactive" value={false}>
          Inactive
        </ToggleButton>
      </ToggleButtonGroup>
    ),
  };

  const filters = [activeFilter];

  const filterRows = (): SortableTableRow[] => {
    let filteredRows: SortableTableRow[] = rows;
    filters.forEach((f) => {
      filteredRows = f.filter(filteredRows);
    });
    return filteredRows;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="right" alignItems="center">
          {viewMode === CustomViewToggleViewType.GRID && (
            <>
              <Grid item>
                <SearchPaper variant="outlined">
                  <InputBase
                    sx={{ width: '100%' }}
                    placeholder="Search"
                    value={searchValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
                    startAdornment={
                      <SearchIconContainer>
                        <SearchIcon />
                      </SearchIconContainer>
                    }
                    endAdornment={
                      <SearchIconContainer>
                        <ClearSearchIcon onClick={() => setSearchValue('')} />
                      </SearchIconContainer>
                    }
                    inputProps={{ 'aria-label': 'search list' }}
                  />
                </SearchPaper>
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={activeFiltered ? 'active' : 'inactive'}
                  exclusive
                  onChange={(_event: React.MouseEvent<HTMLElement>, newAlignment: string) => setActiveFiltered(newAlignment === 'active')}
                  aria-label="Platform">
                  <ToggleButton value="active">Active</ToggleButton>
                  <ToggleButton value="inactive">Inactive</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </>
          )}
          <Grid item>
            <CustomViewToggle value={viewMode} handleChangeView={setViewMode} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {viewMode === CustomViewToggleViewType.TABLE ? (
          <CustomSortableTable
            filters={filters}
            searchable={['name']}
            title={'Locations'}
            counter={'filtered'}
            actions={[
              {
                key: 'create',
                label: 'Create',
                onClick: handleCreate,
              },
            ]}
            headers={[
              {
                key: 'name',
                label: 'Name',
              },
              {
                key: 'active',
                label: 'Active',
              },
            ]}
            rows={filterRows()}
          />
        ) : (
          <Grid container spacing={2}>
            <GridActionCard gridSize={4} fill={true} onClick={handleCreate} />
            {pageState.locations
              .filter((item) => item.active === activeFiltered)
              .filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
              .map((item) => (
                <GridCard
                  fill={true}
                  key={item.id}
                  gridSize={4}
                  title={item.name}
                  subTitle={`Active: ${StringUtils.booleanToYesNo(item.active)}`}
                  handleEdit={() => handleEdit(item.id)}
                  handleView={() => handleView(item.id)}
                />
              ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ListLocationsComponent;
