import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Types } from '@blumtechgroup/blum-ui-utils';
import { CognitoUser, AdminUser, OrganisationUser, LocationUser, ClinicianUser } from '../../types';
import {
  getAdminUserQuery,
  getOrganisationUserQuery,
  getLocationUserQuery,
  getClinicianUserQuery,
  GetUserRoleResponse,
  GetUserVariables,
  getUserRoleQuery,
  GetAdminUserResponse,
  GetOrganisationUserResponse,
  GetLocationUserResponse,
  GetClinicianUserResponse,
} from './queries';
import { CognitoContext } from '../CognitoProvider';
import { useLazyQuery } from '@apollo/client';
import { CustomBackdrop } from '@blumtechgroup/blum-react-core-components';

type CustomError = Types.CustomError;

export enum EnumUserRole {
  ADMIN = 'admin',
  ORGANISATION_ADMIN = 'organisation_admin',
  LOCATION_ADMIN = 'location_admin',
  CLINICIAN = 'clinician',
}

export const adminRoles = [EnumUserRole.ADMIN];
export const orgRoles = [EnumUserRole.ORGANISATION_ADMIN];
export const locRoles = [EnumUserRole.LOCATION_ADMIN];
export const clinicianRoles = [EnumUserRole.CLINICIAN];

interface AuthContextItems {
  user: AdminUser | OrganisationUser | LocationUser | ClinicianUser | null;
  isActive: boolean | undefined;
  loggedInUser: CognitoUser | null;
  userRole: EnumUserRole | null;
  signIn: (username: string, password: string, ignoreSet?: boolean) => Promise<CognitoUser | CustomError | any>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void | CustomError>;
  confirmSignUp: (username: string, code: string) => Promise<true | CustomError>;
  setLoggedInUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  resendSignUp: (username: string) => Promise<true | CustomError>;
  completeNewPassword: (password: string, attributes: { given_name: string; family_name: string }) => Promise<true | CustomError>;
}

export const AuthContext = React.createContext<AuthContextItems | null>(null);

type Props = { children: React.ReactNode };

const AuthProvider = ({ children }: Props): React.ReactElement => {
  const cognitoContext = useContext(CognitoContext);
  if (cognitoContext === null) {
    throw new Error('No AuthContext');
  }

  const { Auth, setToken, removeToken } = cognitoContext;

  const [user, setUser] = useState<AdminUser | OrganisationUser | LocationUser | ClinicianUser | null>(null);
  const [isActive, setIsActive] = useState<boolean | undefined>(undefined);
  const [userRole, setUserRole] = useState<EnumUserRole | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<CognitoUser | null>(null);

  const [getRole, { data: rolesResponse, loading: roleLoading }] = useLazyQuery<GetUserRoleResponse, GetUserVariables>(getUserRoleQuery);

  const [getAdmin, { data: adminResponse, loading: adminLoading }] = useLazyQuery<GetAdminUserResponse, GetUserVariables>(getAdminUserQuery);
  const [getOrganisationUser, { data: organisationUserResponse, loading: orgLoading }] = useLazyQuery<GetOrganisationUserResponse, GetUserVariables>(getOrganisationUserQuery);
  const [getLocationUser, { data: locationUserResponse, loading: locLoading }] = useLazyQuery<GetLocationUserResponse, GetUserVariables>(getLocationUserQuery);
  const [getClinicianUser, { data: clinicianUserResponse, loading: clinicianLoading }] = useLazyQuery<GetClinicianUserResponse, GetUserVariables>(getClinicianUserQuery);

  const isLoading = roleLoading || adminLoading || orgLoading || locLoading || clinicianLoading;

  const fetchUser = useCallback(() => {
    if (loggedInUser?.attributes?.sub && userRole) {
      const request = { variables: { id: loggedInUser.attributes.sub } };

      if (userRole === EnumUserRole.ADMIN) {
        getAdmin(request);
      }
      if (userRole === EnumUserRole.ORGANISATION_ADMIN) {
        getOrganisationUser(request);
      }
      if (userRole === EnumUserRole.LOCATION_ADMIN) {
        getLocationUser(request);
      }
      if (userRole === EnumUserRole.CLINICIAN) {
        getClinicianUser(request);
      }
    }
  }, [getAdmin, getClinicianUser, getLocationUser, getOrganisationUser, loggedInUser, userRole]);

  useEffect(() => {
    if (adminResponse) {
      const userResponse = adminResponse.users_admin[0];
      setUser({
        ...userResponse,
        user_type: 'Admin',
      } as AdminUser);
      setIsActive(userResponse.active);
    }

    if (organisationUserResponse) {
      const userResponse = organisationUserResponse.users_organisation_admin[0];
      setUser({
        ...userResponse,
        user_type: userResponse.organisation.name,
      } as OrganisationUser);
      setIsActive(userResponse.active && userResponse.organisation.active);
    }

    if (locationUserResponse) {
      const userResponse = locationUserResponse.users_location_admin[0];
      setUser({
        ...userResponse,
        user_type: `${userResponse.organisation.name} - ${userResponse.location.name}`,
      } as LocationUser);
      setIsActive(userResponse.active && userResponse.organisation.active && userResponse.location.active);
    }

    if (clinicianUserResponse) {
      const userResponse = clinicianUserResponse.users_clinician[0];
      setUser({
        ...userResponse,
        user_type: `${userResponse.organisation.name} - ${userResponse.location.name}`,
      } as ClinicianUser);
      setIsActive(userResponse.active && userResponse.organisation.active && userResponse.location.active);
    }
  }, [adminResponse, clinicianUserResponse, locationUserResponse, organisationUserResponse]);

  useEffect(() => {
    if (userRole) {
      fetchUser();
    }
  }, [fetchUser, userRole]);

  useEffect(() => {
    if (loggedInUser?.attributes?.sub) {
      getRole({
        variables: {
          id: loggedInUser.attributes.sub,
        },
      });
    } else {
      setUserRole(null);
    }
  }, [getRole, loggedInUser]);

  useEffect(() => {
    if (rolesResponse) {
      setUserRole(rolesResponse.user_roles[0].role as EnumUserRole);
    }
  }, [rolesResponse]);

  useEffect(() => {
    if (!loggedInUser?.attributes?.sub) {
      Auth.currentAuthenticatedUser()
        .then(async (user) => {
          setLoggedInUser(user);
          setToken(user.signInUserSession.idToken.jwtToken);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [setToken, Auth, loggedInUser]);

  const authContext: AuthContextItems = {
    user,
    isActive,
    userRole,
    loggedInUser,
    setLoggedInUser,
    forgotPassword: async (email) => Auth.forgotPassword(email).catch((error) => ({ error: true, ...error })),
    signIn: async (emailAddress: string, password: string) => {
      const cognitoUser = await Auth.signIn(emailAddress.toLowerCase(), password);
      if (cognitoUser.attributes) {
        setLoggedInUser(cognitoUser);
        if (cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
          setToken(cognitoUser.signInUserSession.accessToken.jwtToken);
        }
      }
      return cognitoUser;
    },
    signOut: async () => {
      setToken(null);
      removeToken();
      await Auth.signOut();
      setLoggedInUser(null);
    },
    completeNewPassword: async (password, attributes) => {
      try {
        await Auth.completeNewPassword(loggedInUser, password, attributes);
        return true;
      } catch (error: any) {
        return error;
      }
    },
    confirmSignUp: async (username: string, code: string) => {
      try {
        await Auth.confirmSignUp(username.toLowerCase(), code);
        return true;
      } catch (error: any) {
        return error;
      }
    },
    resendSignUp: async (username: string) => {
      try {
        await Auth.resendSignUp(username);
        return true;
      } catch (error: any) {
        return error;
      }
    },
  };
  return (
    <AuthContext.Provider value={authContext}>
      <>
        {children}
        {isLoading && <CustomBackdrop label="Loading" />}
      </>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
