import React from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectLocationUsersQuery, Location } from './queries';
import ListTbsaResultsComponent from '../../../../../components/Global/Organisations/Locations/Tbsa/List';

type UrlParams = {
  location_id: string;
};

const ListTbsaResults = (): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();
  const { data: locationData } = useQuery<Location>(selectLocationUsersQuery, { fetchPolicy: 'no-cache', variables: { locationId: location_id } });

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Locations', link: `/org/locations` },
        { label: locationData?.locations_by_pk.name ?? '', link: `/org/locations/${location_id}` },
        { label: 'TBSA results' },
      ]}>
      <ListTbsaResultsComponent locationId={location_id} />
    </DashboardLayout>
  );
};

export default ListTbsaResults;
