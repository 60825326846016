import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { createClinicianUserInitQuery, Location } from './queries';
import { useQuery } from '@apollo/client';
import { CreateClinicianUsersComponent } from '../../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  location_id: string;
};

const CreateClinicianUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });

  const { data: createClinicianUserInitData } = useQuery(createClinicianUserInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (createClinicianUserInitData) {
      setLocation(createClinicianUserInitData.locations_by_pk);
    }
  }, [createClinicianUserInitData]);

  const handleFinish = (userId: string) => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/clinicians/${userId}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Clinicians', link: `/admin/organisations/${organisation_id}/locations/${location_id}/clinicians` },
        { label: 'Create' },
      ]}>
      <CreateClinicianUsersComponent organisationId={organisation_id!} locationId={location_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default CreateClinicianUsers;
