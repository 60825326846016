import { StringUtils } from '@blumtechgroup/blum-ui-utils';

export interface ClinicianLicense {
  license_type_id: string;
}

export interface ClinicianUser {
  first_name: string;
  last_name: string;
  email: string;
  active: boolean;
  license: ClinicianLicense | undefined;
}

export interface ValidationErrors {
  first_name: string | boolean;
  last_name: string | boolean;
  email: string | boolean;
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide a valid email address',
};

export enum ClinicianUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ACTIVE,
  LICENSE,
  INIT,
}

export const clinicianUserReducer = (state: ClinicianUser, action: { type: ClinicianUserReducerAction; value: any }): ClinicianUser => {
  switch (action.type) {
    case ClinicianUserReducerAction.FIRST_NAME:
      return { ...state, first_name: StringUtils.capitaliseFirst(action.value) };
    case ClinicianUserReducerAction.LAST_NAME:
      return { ...state, last_name: StringUtils.capitaliseFirst(action.value) };
    case ClinicianUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase() };
    case ClinicianUserReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case ClinicianUserReducerAction.LICENSE:
      if (!action.value) {
        return {
          ...state,
          license: undefined,
        };
      }

      return {
        ...state,
        license: {
          license_type_id: action.value,
        },
      };
    case ClinicianUserReducerAction.INIT:
      const { first_name, last_name, email, active, license } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
        active,
        license,
      };
      return { ...(obj as ClinicianUser) };
    default:
      throw new Error();
  }
};
