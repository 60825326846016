import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { selectLocationUserInitQuery, LocationUser } from './queries';
import { ViewLocationUsersComponent } from '../../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  location_id: string;
  user_id: string;
};

const ViewLocationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id, location_id, user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<LocationUser>({
    first_name: '',
    last_name: '',
    organisation: {
      name: '',
    },
    location: {
      name: '',
    },
  });
  const { data: locationUserInitData } = useQuery(selectLocationUserInitQuery(user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationUserInitData) {
      setUser(locationUserInitData.users_location_admin_by_pk);
    }
  }, [locationUserInitData]);

  const handleEdit = () => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/users/${user_id}/edit`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: user.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: user.location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Users', link: `/admin/organisations/${organisation_id}/locations/${location_id}/users` },
        { label: StringUtils.stringFromArray([user.first_name, user.last_name]) },
      ]}>
      <ViewLocationUsersComponent userId={user_id!} handleEdit={handleEdit} />
    </DashboardLayout>
  );
};

export default ViewLocationUsers;
