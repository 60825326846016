import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { selectLocationInitQuery, Location } from './queries';
import { ViewLocationComponent } from '../../../../components/Global';

type UrlParams = {
  location_id: string;
};

const ViewLocation = (): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
    users_location_admins_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    users_clinicians_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    tbsa_results_aggregate: {
      aggregate: {
        count: 0,
      },
    },
  });

  const { data: locationInitData } = useQuery(selectLocationInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (locationInitData) {
      setLocation(locationInitData.locations_by_pk);
    }
  }, [locationInitData]);

  const actions = [
    {
      title: 'Users',
      counter: location.users_location_admins_aggregate.aggregate.count,
      createLink: `/org/locations/${location_id}/users/create`,
      viewLink: `/org/locations/${location_id}/users`,
    },
    {
      title: 'Clinicians',
      counter: location.users_clinicians_aggregate.aggregate.count,
      createLink: `/org/locations/${location_id}/clinicians/create`,
      viewLink: `/org/locations/${location_id}/clinicians`,
    },
    {
      title: 'TBSA results',
      counter: location.tbsa_results_aggregate.aggregate.count,
      viewLink: `/org/locations/${location_id}/tbsa`,
    },
  ];

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Home', link: '/org/home' }, { label: 'Locations', link: `/org/locations` }, { label: location.name }]}>
      <ViewLocationComponent actions={actions} />
    </DashboardLayout>
  );
};

export default ViewLocation;
