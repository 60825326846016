import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { DashboardLayout, GridCard } from '@blumtechgroup/blum-react-core-components';
import { selectOrganisationInitQuery, Organisation } from './queries';

type UrlParams = {
  organisation_id: string;
};

const ViewOrganisation = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    users_organisation_admins_aggregate: {
      aggregate: {
        count: 0,
      },
    },
    locations_aggregate: {
      aggregate: {
        count: 0,
      },
    },
  });

  const { data: organisationInitData } = useQuery(selectOrganisationInitQuery(organisation_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (organisationInitData) {
      setOrganisation(organisationInitData.organisations_by_pk);
    }
  }, [organisationInitData]);

  const handleAction = (link: string) => {
    navigate(link);
  };

  const actions = [
    {
      title: 'Users',
      counter: organisation.users_organisation_admins_aggregate.aggregate.count,
      createLink: `/admin/organisations/${organisation_id}/users/create`,
      viewLink: `/admin/organisations/${organisation_id}/users`,
    },
    {
      title: 'Locations',
      counter: organisation.locations_aggregate.aggregate.count,
      createLink: `/admin/organisations/${organisation_id}/locations/create`,
      viewLink: `/admin/organisations/${organisation_id}/locations`,
    },
  ];

  return (
    <DashboardLayout breadcrumbs={[{ label: 'Admin', link: '/admin/home' }, { label: 'Organisations', link: '/admin/organisations' }, { label: organisation.name }]}>
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            title={action.title}
            counter={action.counter}
            editLabel="Create"
            handleEdit={() => handleAction(action.createLink)}
            handleView={() => handleAction(action.viewLink)}
          />
        ))}
      </Grid>
    </DashboardLayout>
  );
};

export default ViewOrganisation;
