import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { useQuery } from '@apollo/client';
import { editLocationInitQuery, Location } from './queries';
import { LocationEditComponent } from '../../../../components/Global';

type UrlParams = {
  location_id: string;
};

const LocationEdit = (): React.ReactElement => {
  const navigate = useNavigate();

  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      id: '',
      name: '',
    },
  });

  const { data: editLocationInitQueryData } = useQuery(editLocationInitQuery(location_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (editLocationInitQueryData) {
      const { locations_by_pk } = editLocationInitQueryData;
      setLocation(locations_by_pk);
    }
  }, [editLocationInitQueryData]);

  const handleFinish = () => {
    navigate(`/org/locations/${location_id}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Home', link: '/org/home' },
        { label: 'Locations', link: `/org/locations` },
        { label: location.name, link: `/org/locations/${location_id}` },
        { label: 'Edit' },
      ]}>
      <LocationEditComponent locationId={location_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default LocationEdit;
