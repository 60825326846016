import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '@blumtechgroup/blum-react-core-components';
import { StringUtils } from '@blumtechgroup/blum-ui-utils';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { editLocationUserInitQuery, LocationUser } from './queries';
import { EditLocationUsersComponent } from '../../../../../../components/Global';

type UrlParams = {
  organisation_id: string;
  location_id: string;
  user_id: string;
};

const EditLocationUsers = (): React.ReactElement => {
  const navigate = useNavigate();

  const { organisation_id, location_id, user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<LocationUser>({
    first_name: '',
    last_name: '',
    location: {
      name: '',
      organisation: {
        name: '',
      },
    },
  });

  const { data: editLocationUserInitData } = useQuery(editLocationUserInitQuery(user_id!), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (editLocationUserInitData) {
      const { users_location_admin_by_pk } = editLocationUserInitData;
      setUser(users_location_admin_by_pk);
    }
  }, [editLocationUserInitData]);

  const handleFinish = () => {
    navigate(`/admin/organisations/${organisation_id}/locations/${location_id}/users/${user_id}`);
  };

  return (
    <DashboardLayout
      breadcrumbs={[
        { label: 'Admin', link: '/admin/home' },
        { label: 'Organisations', link: '/admin/organisations' },
        { label: user.location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: user.location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Users', link: `/admin/organisations/${organisation_id}/locations/${location_id}/users` },
        { label: StringUtils.stringFromArray([user.first_name, user.last_name]), link: `/admin/organisations/${organisation_id}/locations/${location_id}/users/${user_id}` },
        { label: 'Edit' },
      ]}>
      <EditLocationUsersComponent userId={user_id!} handleFinish={handleFinish} />
    </DashboardLayout>
  );
};

export default EditLocationUsers;
